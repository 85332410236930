import React from 'react';
import UserIcon from '../icons/user';
import styles from './MemberTeaser.module.scss';
console.log(styles['member-avatar']);
export function MemberTeaser(props){
  let { profile_image_url, first_name, last_name } = props;

    const MemberName = () => (
      <div className={styles['member-avatar__text']}>{first_name} {last_name}</div>
    );

    if(!profile_image_url){
        return (
          <div className={styles['member-avatar']}>
            <div className={styles['member-avatar__image']}>{UserIcon}</div>
            <MemberName />
          </div>
        );
    }

    return (
      <div className={styles['member-avatar']}>
        <div className={styles['member-avatar__image']} style={{ backgroundImage: (profile_image_url ? `url(${profile_image_url})` : null) }}></div>
        <MemberName />
      </div>
    );
};
