import React, { useEffect, useState } from 'react';
import { MemberTeaser } from '../MemberTeaser';
import moment from 'moment';
import classNames from 'classnames/bind';
import styles from './CheckIn.module.scss';

const cx = classNames.bind(styles);

const StatusComponent = (props) => {
  let { label, status } = props;
  return (
      <div className={`user__status user__status--${status}`}>
          <div className="user__status__container">
              <div className="status__label">{label}</div>
              <div className="status__key"></div>
          </div>
      </div>
  );
};

export function CheckIn({ checkin, selectedCheckin, setSelectedCheckin }) {
  const checkinLabel = checkin.entry_paid ? 'Paid' : 'Not Paid';
  const checkinSubscriptionStatus = checkin.entry_paid ? 'green' : 'red';
  const expiresOn = checkin.entry_paid && checkin.payment_expires ? <div>Expires: {checkin.payment_expires}</div> : null;
  const isSelected = selectedCheckin && selectedCheckin.id === checkin.id;
  // useEffect(() => {
  //   setTimeout(() => {
  //     setCheckinSubscriptionStatus(null);
  //   }, 2000);
  // });

  // useEffect(() => {
  //   setCheckinSubscriptionStatus(checkin.entry_paid ? 'green' : 'red');
  // }, [checkin.entry_paid]);

  const handleRowOnClick = () => {
      
      if(selectedCheckin && (selectedCheckin.id === checkin.id)){
          setSelectedCheckin(null);
      }else{
          setSelectedCheckin(checkin);
      }
      
  };

  let checkinTime = moment(checkin.entry_date_time);

  const containerClass = cx({
    'checkin': true,
    'checkin--selected': Boolean(isSelected),
    // [`checkin--${checkinSubscriptionStatus}`]: Boolean(checkinSubscriptionStatus)
  });

  return (
      <div className={containerClass} onClick={handleRowOnClick}>
        <div className={styles['checkin__time']}><span>{checkinTime.format('H:mm')}</span></div>
        <div className={styles['checkin__member']}>
          <MemberTeaser
            profile_image_url={checkin.profile_image_thumbnail}
            first_name={checkin.first_name}
            last_name={checkin.last_name}
          />
        </div>
        <div className={styles['checkin__status']}>
            <StatusComponent 
                label={checkinLabel} 
                status={checkinSubscriptionStatus}
            />
        </div>
      </div>
  );
};
