import React, { useState, useEffect } from 'react';
import { Popup } from '@components/Popup';
import { useSelector, useDispatch } from 'react-redux';
import { searchForUser, trackUser } from '../actions';
import UserAvatar from '../components/user_avatar';

function UserToCheckin({user}){

    const dispatch = useDispatch();

    const handleCheckinOnClick = () => dispatch(trackUser(user.id));

    return (
        <div className="checkin__user">
            <UserAvatar profile_image_url={user.profile_image_thumbnail} is_guest={user.is_guest} />
            <div className="user__name">{user.full_name}</div>
            <button className="form__button form__button--secondary__btn" onClick={handleCheckinOnClick}>Check-in</button>
        </div>
    );
}

function ManualCheckin({exitOnClickHandler}){

    const dispatch = useDispatch();
    const checkinUsers = useSelector(state => state.checkinUsers);
    const errorMessage = useSelector(state => state.errorMessage);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    
    useEffect(() => {

        return function(){
            setName('');
            setEmail('');
        };
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        
        dispatch(searchForUser(name, email));
    };

    return (
        <Popup title="Manual check-in" exitOnClickHandler={exitOnClickHandler}>
            <form onSubmit={handleSubmit}>
                <div className="form__row__group">
                    <div className="form__row">
                        <label className="form__label" htmlFor="name">Existing Members</label>
                        <input id="name" className="form__input" type="text" name="name" placeholder="Enter name" value={name} onChange={(e) => {setName(e.target.value); setEmail('')}} />
                    </div>
                    <div className="form__row form__row--or">
                        <div className="or__label">OR</div>
                    </div>
                    <div className="form__row">
                    <label className="form__label" htmlFor="email">New Members (From GymDash Network)</label>
                        <input id="email" className="form__input" type="email" name="email" placeholder="Enter email" value={email} onChange={(e) => {setEmail(e.target.value); setName('')}} />
                    </div>
                    
                </div>
                <button className="form__button form__button--primary__btn">Search</button>
            </form>
            <div className="checkin__users">
                {errorMessage ? <div className="single__error__message">{errorMessage}</div> : null}
                {checkinUsers ? checkinUsers.map((user, idx) => <UserToCheckin key={idx} user={user} />) : null}
            </div>
        </Popup>
    );
}

export default ManualCheckin;