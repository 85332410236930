import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UserIcon from './icons/user';
import {
    assignDiscount,
    deleteCheckin
} from '../actions';

const UserImage = ({currentUser, name}) => {

    return (
        <img className="checkin__image" src={currentUser.profile_image_small} alt={name} title={name} />
    );
};

const PlaceholderImage = () => {
    return (
        <div className="checkin__image checkin__image--placeholder">{UserIcon}</div>
    );
};

function LastCheckin({selectedCheckin}){

    const dispatch = useDispatch();
    const lastCheckin = useSelector(state => state.lastCheckin);
    
    if(!lastCheckin && !selectedCheckin){
        return null;
    }

    const theCheckin = selectedCheckin || lastCheckin;
    
    const name = `${theCheckin.first_name} ${theCheckin.last_name}`;
    
    const CheckinImage = ({currentUser}) => {
        return currentUser.profile_image_small ? <UserImage currentUser={currentUser} name={name} /> : <PlaceholderImage />
    };

    const assignDiscountOnClickHandler = () => dispatch(assignDiscount());
    const deleteCheckinOnClickHandler = () => dispatch(deleteCheckin());

    return (
        <div className="latest__checkin">
            <CheckinImage currentUser={theCheckin} name={name} />
            <div className="checkin__details">
                <div className="checkin__name">{name}</div>
                <div className="checkin__date">{theCheckin.entry_date_label}</div>
                <div className="checkin__user__actions">
                    {/* <button className="action__btn" onClick={assignDiscountOnClickHandler}>Assign Discount</button> */}
                    <button className="action__btn action__btn--danger" onClick={deleteCheckinOnClickHandler}>Delete Checkin</button>
                </div>
            </div>
        </div>
    );
}

export default LastCheckin;