import React from 'react';
import Layout from '@components/layout';
import SecuredComponent from '@components/secured';
import { CheckinFeed } from '@components/CheckInFeed';
import { useAuth } from '@hooks/useAuth';
import { SystemNotification } from '@components/SystemNotification';

function AppPage(){
    
    const auth = useAuth();

    return (
        <Layout>
            <SystemNotification />
            <SecuredComponent component={CheckinFeed} path="/feed" />
        </Layout>
    );
};

export default AppPage;
