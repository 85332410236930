import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { 
    addCheckin, 
    webSocketConnect, 
    webSocketDisconnect,
    takePayment,
    exitTakePayment,
    selectCheckin,
    clearCheckins,
    manualCheckin,
    exitManualCheckin,
    exitAssignDiscount,
    exitDeleteCheckin,
    deleteUserCheckin,
    getCheckins,
} from '../../actions';
import { SEO } from '@components/SEO';
import { MenuComponent } from '@components/Menu';
import LastCheckin from '../last_checkin';
import TakePaymentPopup from '../take_payment_popup';
import ManualCheckin from '../manual_checkin_popup';
import AssignDiscountPopup from '../assign_discount_popup';
import {
    ChangeCheckinDatePopup,
    ChangeCheckinDatePopupButton,
} from '../ChangeDatePopup';
import EyeIcon from '../icons/eye';
import EyeOffIcon from '../icons/eye_off';
import {
    PopupConfirm
} from '@components/Popup';
import { CheckIn } from '../CheckIn';
import { useLocation } from '@reach/router';
import styles from './CheckInFeed.module.scss';
import { Link } from 'gatsby';
import { navigate } from 'gatsby'

const PaymentsInfo = ({payments}) => {

    const [display, setDisplay] = useState(false);

    if(display){
        return (
            <div className="payments__overview">
                <div className="payment__detail payment__detail--primary">{payments && payments.total_payments_after_discount ? `Total after discount: £${payments.total_payments_after_discount}` : null}</div>
                <div className="payment__detail">({payments && payments.total_payments ? `Total: £${payments.total_payments}` : null} - {payments && payments.total_discounts ? `Discounts given: £${payments.total_discounts}` : null})</div>
                <div className="payment__detail">
                    <button onClick={() => setDisplay(false)} type="button" className="payment__totals__toggle">{EyeOffIcon}</button>
                </div>
            </div>
        );
    }

    return (
        <div className="payments__overview">
            <div className="payment__detail">
                <button onClick={() => setDisplay(true)} type="button" className="payment__totals__toggle"><div className="payment__totals__toggle__label">View Totals</div> {EyeIcon}</button>
            </div>
        </div>
    );
};

export function CheckinFeed() {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentGym = useSelector(state => state.currentGym);
    const isDoingManualCheckin = useSelector(state => state.isDoingManualCheckin);
    const isTakingPayment = useSelector(state => state.isTakingPayment);
    const isUndoingMerge = useSelector(state => state.isDeletingCheckin);
    // const selectedCheckin = useSelector(state => state.selectedCheckin);
    const checkinData = useSelector(state => state.todaysCheckins);
    const [activeDate, setActiveDate] = useState(null);
    const [currentDate, setCurrentDate] = useState(moment());
    const [selectedCheckin, setSelectedCheckin] = useState(null);
    const socket = useSelector(state => state.socket);
    const ourRef = useRef();

    const handleClick = function(e){
        let className = e.target.className;

        if(className === 'action__btn__label'){
            return;
        }

        dispatch(selectCheckin(null));
    }

    const handleDateChange = async (date) => {
        setActiveDate(date);
        navigate(`/app/feed?date=${date.format('YYYY-MM-DD')}`)
        dispatch(getCheckins(currentGym.gym_hash, date));
    };

    useEffect(() => {
        dispatch(webSocketConnect());

        ourRef.current.addEventListener('click', handleClick);
        
        return function(){
            dispatch(webSocketDisconnect());
            dispatch(selectCheckin(null));
            dispatch(clearCheckins());
        };
    }, []);

    useEffect(() => {
        // const params = new URLSearchParams(location.search);
        // setActiveDate(moment(params.get('date')));
        // dispatch(getCheckins(currentGym.gym_hash, activeDate));
        if (location) {
            const params = new URLSearchParams(location.search);
            const date = moment(params.get('date') || new Date());
            setActiveDate(date);
            dispatch(getCheckins(currentGym.gym_hash, date));
        }
    }, [location]);

    useEffect(() => {
        if(socket){
            socket.on('checkin_scan', (data) => {
                dispatch(addCheckin(data));
            })
        }
    }, [socket]);

    const manualCheckinOnClickHandler = () => dispatch(manualCheckin());
    const manualCheckinExitOnClickHandler = () => dispatch(exitManualCheckin());

    const deleteCheckinOnClickHandler = () => {
        dispatch(deleteUserCheckin(selectedCheckin));
        setSelectedCheckin(null);
    };
    const deleteCHeckinExitOnClickHandler = () => dispatch(exitDeleteCheckin());

    const takePaymentOnClickHandler = () => dispatch(takePayment());
    const handleExitTakePaymentOnClick = () => dispatch(exitTakePayment());

    const handleExitAssignDiscountOnClick = () => dispatch(exitAssignDiscount());
    if (checkinData) {
        // delete checkinData.checkins;
    }
    
    return (
        <>
            <SEO title="Home" />
            <div className="main__layout" ref={ourRef}>
                <div className="left__column">
                    <MenuComponent />
                </div>
                <div className="middle__column">
                    <div className="component__wrapper">
                        <div className="component__inner">
                            <div className="component__wrapper__header">
                                <h1>Check-in Feed / {activeDate && activeDate.format('Do MMMM YYYY')}</h1>
                                <div className="component__wrapper__actions">
                                    <ChangeCheckinDatePopupButton />
                                    <button className="action__btn action__btn--white" onClick={manualCheckinOnClickHandler} data-tip="Manual Checkin">
                                        <span className="action__btn__label">Manual Check-in</span>
                                        <span className="action__btn__label action__btn__label--mobile">Manual Check-in</span>
                                    </button>
                                    <button className="action__btn action__btn--white" disabled={!selectedCheckin} onClick={takePaymentOnClickHandler}>
                                        <span className="action__btn__label">Take a payment</span>
                                        <span className="action__btn__label action__btn__label--mobile">Pay</span>
                                    </button>
                                </div>
                            </div>
                            <div className="component__container">
                                <div className="component">
                                    <div className="component__body">
                                        {(currentDate.diff(activeDate, 'day')) ? (
                                            <Link to='/app/feed/' className={styles['current-date-message']}>Go back to todays checkin feed - {currentDate.format('Do MMMM YYYY')}!</Link>
                                        ) : null}
                                        <div className="data__table" style={{flex: '2'}}>
                                            {!checkinData || (checkinData && (!checkinData.checkins || !checkinData.checkins.length)) ? <div className="table__row"><div className="table__column">No checkins for this date...</div></div> : null}
                                            {checkinData && checkinData.checkins && checkinData.checkins.length ? checkinData.checkins.map((checkin, idx) => <CheckIn selectedCheckin={selectedCheckin} setSelectedCheckin={setSelectedCheckin} key={idx} checkin={checkin} />) : null}   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="right__column">
                    <LastCheckin selectedCheckin={selectedCheckin} />
                </div>
            </div>
            {isDoingManualCheckin ? <ManualCheckin exitOnClickHandler={manualCheckinExitOnClickHandler} /> : null }
            {selectedCheckin && isTakingPayment ? 
                <TakePaymentPopup 
                    userId={selectedCheckin.user_id} 
                    userName={`${selectedCheckin.first_name} ${selectedCheckin.last_name}`}
                    userDiscount={{
                        name: selectedCheckin.discount_name,
                        amount: selectedCheckin.discount_amount
                    }}
                    exitOnClickHandler={handleExitTakePaymentOnClick} 
                /> : null}
            
            {selectedCheckin ? 
                <AssignDiscountPopup 
                    userId={selectedCheckin.user_id} 
                    userName={`${selectedCheckin.first_name} ${selectedCheckin.last_name}`} 
                    userDiscount={{
                        name: selectedCheckin.discount_name,
                        amount: selectedCheckin.discount_amount
                    }}
                    exitOnClickHandler={handleExitAssignDiscountOnClick}
                /> : null}
            {isUndoingMerge ? <PopupConfirm 
                onConfirmHandler={deleteCheckinOnClickHandler}
                onCancelHandler={deleteCHeckinExitOnClickHandler}
                message="Are you sure you want to delete this check-in?" 
            /> : null}
            <ChangeCheckinDatePopup
                inputDate={activeDate}
                onClickHandler={handleDateChange}
            />
        </>
    );
};